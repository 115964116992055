body, html, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #111827;
  position: relative;
  z-index: -10;
}

.background {
  background: url('./images/looper-bg.png') no-repeat center center fixed;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.overlay {
  position: relative;
  z-index: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App {
  text-align: center;
  flex: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* src/App.css */

/* Other styles remain unchanged */

@media (max-width: 768px) {
  .responsive-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
  }

  .responsive-grid div {
    margin-bottom: 0;
  }

  .flex.flex-col.space-y-2 {
    gap: 4px;
  }

  .text-xs {
    font-size: 0.75rem;
  }

  .text-sm {
    font-size: 0.875rem;
  }

  .text-base {
    font-size: 1rem;
  }

  .text-lg {
    font-size: 1.125rem;
  }

  .text-xl {
    font-size: 1.25rem;
  }

  .mb-4 {
    margin-bottom: 0.5rem;
  }

  .mb-6 {
    margin-bottom: 1rem;
  }

  .p-6 {
    padding: 1rem;
  }

  .p-4 {
    padding: 0.5rem;
  }

  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
